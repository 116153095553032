import { useEffect, useRef, useState } from 'react';
import { getCourseInfo, getEnrollmentStatus } from './utils/courseDetail';

function useCourseDetail(courseID) {
  const [courseInfo, setCourseInfo] = useState({});
  const [courseInfoLoaded, setCourseInfoLoaded] = useState(false);
  const [enrollmentStatus, setEnrollmentStatus] = useState({});
  const [enrollmentStatusLoaded, setEnrollmentStatusLoaded] = useState(false);
  const [noCourseFound, setNoCourseFound] = useState(false);
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      getCourseInfo(courseID, courseInfoCallback, courseInfoErrorCallback);
      getEnrollmentStatus(courseID, EnrollmentStatusCallback);
      hasFetchedData.current = true;
    }
  }, []);
  const courseInfoCallback = (data) => {
    setCourseInfo(data);
    setCourseInfoLoaded(true);
  };

  const courseInfoErrorCallback = (error) => {
    setNoCourseFound(true);
    setCourseInfoLoaded(true);
  };

  const EnrollmentStatusCallback = (data) => {
    setEnrollmentStatus(data);
    setEnrollmentStatusLoaded(true);
  };

  return [
    courseInfo,
    courseInfoLoaded,
    enrollmentStatus,
    enrollmentStatusLoaded,
    noCourseFound,
  ];
}

export default useCourseDetail;
